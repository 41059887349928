<template>
  <div>
    <b-row class="my-3">
      <b-col cols="9">
        <div>
          <b-input
            placeholder="Pesquisar paciente"
            v-model="filters.patient"
            debounce="1000"
          />
        </div>
      </b-col>
      <b-col cols="3">
        <div class="filter-row">
          <b-button variant="primary" @click="getMedicalReportsFiles">
            Aplicar
          </b-button>
          <b-button variant="outline-secondary" v-b-toggle.filters-sidebar>
            <FilterFunnel class="icon" />
            Filtros
            <NotificationDot
              :show="!!activatedFilters"
              tooltip-message="Os filtros estão ativos" />
          </b-button>
        </div>
      </b-col>
    </b-row>

    <section>
      <div id="table-container" v-if="medicalReports.length">
        <b-table-simple id="pending-reports-table" class="reports-table">
          <thead>
          <tr>
            <th>Data</th>
            <th>Situação</th>
            <th>Paciente</th>
            <th>Profissional</th>
            <th>Exame</th>
            <th>Localidade exame</th>
            <th>Prazo</th>
            <th></th>
          </tr>
          </thead>

          <tbody>
          <tr v-for="(report, index) in medicalReports" :key="index">
            <td>
                <span>
                  {{ parseDate(report.created_at) }}
                  <br />
                  {{ parseDateToTime(report.created_at) }}
                </span>
            </td>
            <td>
              <b-badge
                pill
                class="badge"
                :style="{
                    'background-color': parseStatusColor(report.diffDays)
                  }"
              >
                {{ parseStatus(report.diffDays) }}
              </b-badge>
            </td>
            <td>
              <PatientBasicInfo :patient="report.patient" />
            </td>
            <td>
              <div
                class="professional-name"
                v-if="report.professionals.length"
              >
                <p class="inline-text">
                  {{
                    report.isUserAListedResponsible
                      ? report.isUserAListedResponsible.person.name
                      : report.professionals[0].person.name
                  }}
                </p>
                <div>
                  <b-badge
                    :id="`professionals-${report['id']}`"
                    class="plus-badge"
                    pill
                    v-if="report['professionals'].length - 1 > 0"
                  >+{{ report['professionals'].length - 1 }}
                  </b-badge>
                  <b-tooltip
                    :target="`professionals-${report['id']}`"
                    placement="top"
                  >
                    <p
                      v-for="(professional, index) in report['professionals']"
                      :key="index"
                    >
                      {{ professional['person']['name'] }}
                    </p>
                  </b-tooltip>
                </div>
              </div>
              <p v-else>Qualquer profissional laudador</p>
            </td>
            <td>
              <p class="inline-text">{{ report['exam']['name'] }}</p>
            </td>
            <td>
              <p>
                {{ getLocalidade(report.file.type_id) }}
              </p>
            </td>
            <td>
              <div
                class="limit-time-container"
                :id="`limit-time-${report.id}`"
              >
                <p>
                  {{ report.days_to_expire }} dias <br />
                  {{ new Date(report.limit_datetime).toLocaleDateString() }}
                </p>
              </div>
            </td>
            <td class="filter-row">
              <b-button
                v-if="report.attendance?.status === 'PAUSED'"
                variant="link"
                @click.stop="startAttendance(report)"
                >
                <span class="draft-button">Rascunho</span>
              </b-button>
              <b-button
                variant="outline-primary"
                @click.stop="startAttendance(report)"
                :disabled="
                    !isUserAResponsible ||
                    (!!report.professionals.length &&
                      !report.isUserAListedResponsible)
                  "
              >
                Laudar
              </b-button>
              <span :id="`view-report-${index}`">
              <button class="btn" v-if="userHasPermission('LaudoMedPen2')">
                <Trash @click="openDeleteModal(report)" class="icon trash" />
              </button>
              </span>
              <b-tooltip :target="`view-report-${index}`" placement="bottom">
                Excluir exame
              </b-tooltip>
            </td>
          </tr>
          </tbody>
        </b-table-simple>
      </div>
      <div class="pagination-position" v-if="medicalReports.length">
        <b-pagination
          v-model="page"
          :total-rows="count"
          :per-page="limit"
          first-number
          last-number
          size="sm"
        />
      </div>
      <div v-else class="no-data">
        <p>Nenhum exame disponível</p>
      </div>
    </section>
    <b-modal id="modal-delete" title="Remover exame da lista">
      <p class="modal-text">
        Você tem certeza que deseja remover o exame
        <strong
        >{{
            selectedMedicalReport ? selectedMedicalReport['exam']['name'] : '-'
          }}
        </strong>
        do paciente
        <strong>{{
            selectedMedicalReport ? selectedMedicalReport['patient']['name'] : '-'
          }}</strong>
        ?
      </p>
      <template #modal-footer="{ cancel }">
        <b-button variant="outline-danger" @click="cancel()">
          Cancelar
        </b-button>
        <b-button
          variant="primary"
          @click="deleteMedicalReport(selectedMedicalReport['id'])"
        >
          Remover
        </b-button>
      </template>
    </b-modal>
    <AppointmentDocsModal
      :docs="docs"
      :typeDoc="typeDoc"
      @openDocumentFile="openDocumentFile"
    />

    <DocumentModal :documents="documents" @close-modal="clearDocuments" />
    <FiltersSidebar @applyFilters="applyFilters" />
  </div>
</template>

<script>
import moment from 'moment'
import { getDocTypes } from '@/utils/documentHelper.js'
import {
  userHasPermission,
  getCurrentClinic,
  getCurrentUser
} from '@/utils/localStorageManager'
import { parseDate, parseDateToTime } from '@/utils/dateHelper'

export default {
  components: {
    AppointmentDocsModal: () =>
      import('@/components/General/AppointmentDocsModal.vue'),
    DocumentModal: () => import('@/components/General/DocumentModal'),
    Trash: () => import('@/assets/icons/trash.svg'),
    FilterFunnel: () => import('@/assets/icons/filter-funnel.svg'),
    FiltersSidebar: () => import('./FiltersSidebar.vue'),
    PatientBasicInfo: () => import('@/components/General/PatientBasicInfo'),
    NotificationDot: () => import('@/components/General/NotificationDot')
  },
  props: {
    status: String
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      user: getCurrentUser(),
      isUserAResponsible: false,
      selectedMedicalReport: null,
      medicalReports: [],
      page: 1,
      limit: 0,
      filters: {},
      count: 0,
      docs: [],
      typeDoc: getDocTypes(),
      documents: [],
      showTable: true
    }
  },

  computed: {
    activatedFilters() {
      return this.filters.period?.start
        || this.filters.period?.end
        || this.filters.status
        || this.filters.patient
        || this.filters.professionals?.length
        || this.filters.items?.length
        || this.filters.date_limit
    }
  },

  async created() {
    await this.getMedicalReportsFiles()
    await this.getMedicalReportsResponsibles()
  },

  methods: {
    userHasPermission,
    parseDate,
    parseDateToTime,
    parseStatus(diffDays) {
      if (diffDays < 0) {
        return 'Expirado'
      } else if (diffDays === 0) {
        return 'A expirar'
      } else {
        return 'Pendente'
      }
    },
    parseStatusColor(diffDays) {
      if (diffDays < 0) {
        return '#FED2CE'
      } else if (diffDays === 0) {
        return '#FCDEC2'
      } else {
        return '#E7FFDB'
      }
    },
    applyFilters(filters) {
      this.filters = filters
      this.getMedicalReportsFiles()
    },
    async getMedicalReportsResponsibles() {
      const response = await this.api.allMedicalReportsResponsibles(
        this.clinic.id
      )
      this.isUserAResponsible = response.data.find(
        responsible => responsible.person.id === this.user.id
      )
    },

    async startAttendance(medicalReport) {
      if (medicalReport.attendance_id) {
        this.$router.push(
          `/laudo-medico/${medicalReport.patient.id}/${medicalReport.attendance_id}/${medicalReport.id}`
        )
      } else {
        try {
          const formId = await this.getDefaultForm()
          const attendance = await this.api.startAttendance(
            this.clinic.id,
            medicalReport.patient.id,
            null,
            formId,
            'MEDICAL_REPORT'
          )

          await this.api.createOrUpdateMedicalReportFile(
            medicalReport.file_id,
            medicalReport.item_id,
            this.status,
            null,
            attendance.data.id,
            medicalReport.id
          )
          this.$router.push(
            `/laudo-medico/${medicalReport.patient.id}/${attendance.data.id}/${medicalReport.id}`
          )
        } catch (error) {
          this.$toast.error(error.message)
        }
      }
    },

    openDeleteModal(medicalReport) {
      this.selectedMedicalReport = medicalReport
      this.$bvModal.show('modal-delete')
    },

    async deleteMedicalReport(id) {
      const isLoading = this.$loading.show()
      try {
        await this.api.deleteMedicalReportFile(id)
        await this.getMedicalReportsFiles()
        this.$bvModal.hide('modal-delete')
        isLoading.hide()
        this.$toast.success('Laudo excluído com sucesso!')
      } catch (error) {
        isLoading.hide()
        this.$toast.error(this.$toast.error(error.message))
      }
    },

    async getDefaultForm() {
      try {
        const response = await this.api.getMedicalReportForm()
        return response.data.id
      } catch (error) {
        this.$toast.error(this.$toast.error(error.message))
      }
    },

    async getMedicalReportsFiles() {
      let professionalFilter = this.filters?.professionals?.map(professional => {
        return professional.value
      }) || []
      if (!(userHasPermission('LaudoMedConfigGer3')
          && userHasPermission('LaudoMedPen1'))) {
        professionalFilter = [this.user.id]
      }
      const response = await this.api.getMedicalReportsFiles(
        {
          patient: this.filters.patient,
          pending_status: this.filters.status,
          period: this.filters.period,
          professionals: professionalFilter,
          items:
            this.filters?.items?.map(exam => {
              return exam.value
            }) || [],
          date_limit: this.filters.date_limit,
          status: ['PENDING', 'EXPIRED'],
          clinic_id: this.clinic.id
        },
        this.page
      )
      this.medicalReports = []
      response.data.data.map(res => {
          this.medicalReports.push({
            id: res.id,
            file_id: res.file_id,
            file: res.file,
            fileGroup: res.file_group,
            attendance_id: res.attendance_id,
            attendance: res.attendance,
            attendance_date: res.attendance ? res.attendance.updated_at : null,
            item_id: res.item_id,
            patient: res.file?.patient || res.labpacs_item_reports.patient,
            professionals: res.responsibles,
            exam: res.item,
            limit_datetime: res.limit_datetime,
            days_to_expire:
            res.item?.laudable_clinic_procedure?.limit_time,
            created_at: res.created_at,
            diffDays: moment(res.limit_datetime).startOf('day').diff(moment().startOf('day'), 'days'),
            isUserAListedResponsible: res.responsibles.find(
              responsible => responsible.person.id === this.user.id
            )
          })
      })
      this.resume = response.data.resume
      this.limit = response.data.per_page
      this.count = response.data.total
      this.showTable = true
    },

    handlePeriod(period) {
      this.filters.period.start = period.start
      this.filters.period.end = period.end
      this.getMedicalReportsFiles()
    },

    changeSelectedProfessionals(newValue) {
      this.filters.professional = newValue
      this.getMedicalReportsFiles()
    },

    changeSelectedProcedures(newValue) {
      this.filters.items = newValue
      this.getMedicalReportsFiles()
    },

    goTo(path) {
      this.$router.push(path)
    },

    getPatientAge(birthday) {
      return Math.floor(
        moment(new Date()).diff(moment(birthday), 'years', true)
      )
    },

    async openFilesModal(attendance) {
      await this.getAttendanceFiles(attendance)
      this.$bvModal.show('appointment-docs-modal')
    },
    openDocumentFile(document) {
      this.documents = document
      this.$bvModal.show('document-modal')
    },
    clearDocuments() {
      this.documents = []
    },
    async getAttendanceFiles(attendance) {
      const isLoading = this.$loading.show()
      try {
        const res = await this.api.getAttendanceDocs(attendance.attendance_id)
        this.docs = []
        const laudo = Object.keys(res.data).find(key => {
          return res.data[key].some(doc => doc?.type_id === '11')
        })
        this.docs.push(res.data[laudo])
        this.docs.push(attendance.fileGroup)
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        isLoading.hide()
      }
    },
    goToPatientDashboard(id) {
      const route = id
        ? this.$router.resolve('/pacientes/' + id)
        : this.$router.resolve('/pacientes/' + this.lastPatient.id)
      window.open(route.href, '_blank')
    },
    openWhatsApp(number) {
      const whatsappNumber = number.replace(/\D/g, '')
      if (whatsappNumber.length < 10) {
        this.$toast.warning('Número de telefone sem DDD')
        return
      }
      window.open(
        `https://api.whatsapp.com/send?phone=55${whatsappNumber}`,
        '_blank'
      )
    },
    getLocalidade(typeId){
      const types = {
        'intern': 'Realizado internamente',
        'extern': 'Realizado em outra instituição'
      };

      return typeof types[typeId] !== 'undefined' ? types[typeId] : '-';
    }
  },

  watch: {
    status: function() {
      this.showTable = false
      this.filters.status =
        this.status === 'PENDING' ? ['PENDING', 'EXPIRED'] : ['FINISHED']
      this.filters.period = {
        start: null,
        end: null
      }
      this.getMedicalReportsFiles()
    },

    page: function() {
      this.getMedicalReportsFiles()
    }
  }
}
</script>

<style lang="scss" scoped>
#pending-reports-table {
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  table-layout: fixed;
  margin: 5px 0;

  thead {
    font-weight: 600;
    color: var(--dark-blue);
    border-radius: 8px 8px 0 0;

    tr {
      background-color: var(--neutral-100);

      &:first-child {
        border-radius: 8px 8px 0 0;

        th {
          &:first-child {
            border-radius: 8px 0 0 0;
          }

          &:last-child {
            border-radius: 0 8px 0 0;
          }
        }
      }
    }
  }
}

.draft-button {
  color: var(--states-error);
  border-bottom: 1px solid var(--states-error);
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

.disabled {
  pointer-events: none;
  color: var(--type-placeholder);
  border-color: var(--type-placeholder) !important;
}

.no-data {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;

  p {
    text-align: center;
  }
}

.filter-row {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: end;
}

.badge {
  padding: 8px;
  color: var(--type-active);
}

.trash {
  cursor: pointer;
  width: 25px;
  stroke: var(--states-red-soft);
}

.plus-badge {
  color: var(--neutral-700);
  background-color: var(--neutral-300);
}

#modal-delete {
  .modal-text {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: #525c7a;
    margin-bottom: 20px !important;
  }

  strong {
    color: var(--blue-500);
  }
}
</style>
